import React, { useState, useEffect } from 'react'
import { navigate } from "gatsby"
import Header from "../components/header"
import HeaderMenu from "../components/header-menu"
import Footer from "../components/footer"
import Alert from "../components/alert"
import { CSSTransition } from 'react-transition-group';
import { getUser} from "../services/auth"
import {useEmpreses} from "../helpers/useEmpreses"
import TransitionGranjesMares from "../components/transitionGranjesMares"
import TransitionDataMares from "../components/transitionDataMares"
import TransitionResult from "../components/transitionResult"
import axios from 'axios';

const FitxesMares = props => {
  var empreses=useEmpreses()
  //Step controls
  const [stepActive, setStepActive] = useState(1);
  const [stepPrev, setStepPrev] = useState(0);
  //origenData
  const [granjesMares, setGranjesMares] = useState([]);
  //result Data
  const [allResults, setAllResults ] =  useState([]);
  const [finalResults, setFinalResults ] =  useState([]);
  const [finalValues, setFinalValues ] =  useState({});
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");
  const [isLoading, setLoading] = useState(false)
  const resetStepActive = event => {
    event.preventDefault();
    setStepActive(1);
  }
   
  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  const setResults = (resString,resFinal)=> {
    let pos=stepPrev-1;
    let ar=allResults;
    if (!ar[pos]){
      if (pos<=0) {
        ar.push(resString)
      } else ar.splice(pos, 1, resString);
    } else  ar.splice(pos, 1, resString);
    setAllResults(ar)
    buildResults();
  }

 const buildResults=()=>{
    let _finalResults=new Array();
    for (var i=0;i<allResults.length;i++){
        for (var j=0;allResults[i].length;j++){
          _finalResults.push(allResults[i][j])
          if (j+1>=allResults[i].length) {
            setFinalResults(_finalResults)
            break
          }
        }
    }
  }
  
  const handleStepUp = (value) => {
   if (value!==5) {
      setStepPrev(value);
      setStepActive(value + 1);
   }
  }

  const handleStepDown = (value) => {
     if (value!==1) {
      setStepPrev(value);
      setStepActive(value - 1);
     }
  }

  const getEmpresa =(idEmpresa) =>{
    var empresa="";
    for (var _empresa in empreses.allEmpresa.nodes){
        if (empreses.allEmpresa.nodes[_empresa].idEmpresa===idEmpresa) {
          empresa=empreses.allEmpresa.nodes[_empresa]
          break;
        }
    }
    return empresa;
  }

  useEffect( () => {
    var empresa=""
    if (getUser()) empresa=getEmpresa(getUser().idEmpresa);
      setGranjesMares(empresa.granjesMares)
    }, []);

  const handleConfirm= (e) =>{
      e.preventDefault();
      console.log("confirm "+JSON.stringify(finalValues))
      const fitxesMares_obj={
        "MARCA_ORG": finalValues.origenMaresSelected ,
        "NAU_ORG": finalValues.nauOrigen,
        "DATA":finalValues.data,
        "VERRES_PRO":finalValues.verresPro,
        "NASCUTSVIUS":finalValues.nascutsVius,
        "TRUGESDEST": finalValues.trugesDestete,
        "TPCBAIXESDEST":finalValues.tpcBaixesDest,
        "VERRESNOVES":finalValues.verresNoves,
        "ABORTS":finalValues.aborts,
        "REPETICIONS":finalValues.repeticions,
        "id_usuari":getUser().id,
      }
      console.log("fitxesMares_obj="+JSON.stringify(fitxesMares_obj))
      const headers= {
        'Content-Type': 'application/json'
      }
      axios.defaults.headers.common['Authorization'] =  getUser().token;
      setLoading(true)
      axios.post("/auth/FitxesMares", fitxesMares_obj,{
          headers: headers
      }).then(res => {
           setLoading(false)
          if (res.data.error) {
            console.log("ERROR AL INSERTAR = "+JSON.stringify(res.data) )   
            setMessageAlertStep(res.data.error)
            setShowAlertStep(true)
          }else navigate("/mainMenu")
        }).catch(error => {
          setMessageAlertStep(error.message)
          setShowAlertStep(true)
          setLoading(false)
        })  
    
    }
  return (
      <React.Fragment>
        <div className="granjatec-wrapper">
          <Header/>
          <HeaderMenu />
          <div className="main-container">
              <section className="section heading-opt">
                <div className="heading-opt-title">
                  <h2 className="h5">Fitxes Mares</h2>
                </div>
              </section>
              <section className="section form-opt destete-engreix">
                <form className="steps">
                  <TransitionGranjesMares index={1} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        handleStepActiveUp={handleStepUp}
                        handleStepActiveDown={handleStepDown}
                        arrayData={granjesMares} 
                        callbackStrings={setResults}
                        callbackValues={finalValues}   />

                    <TransitionDataMares index={2} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        handleStepActiveUp={handleStepUp}
                        handleStepActiveDown={handleStepDown}
                        callbackStrings={setResults}
                        callbackValues={finalValues} />
                   
                    <TransitionResult index={3} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        arrayData={finalResults}
                        resetStepActive={resetStepActive}
                        handleConfirm={handleConfirm} />

                </form>
              </section>
              { isLoading && (
              <div className="overlay-loading"></div>
              )}
            </div>
            <CSSTransition
              in={showAlertStep}
              timeout={300}
              classNames={'alertright'}
              unmountOnExit >
              <Alert type="error">
                <div className="alert-message">
                  {messageAlertStep}
                </div>
                <span className="alert-close" onClick={closeAlertStep}>
                  <i className="icon-close"></i>
                </span>
              </Alert>
            </CSSTransition>
          <Footer/>
        </div>
    </React.Fragment>

)}

export default FitxesMares