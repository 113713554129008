import React, { useState, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group';
import ClickNHold from 'react-click-n-hold'; 
import classNames from "classnames"
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Alert from "../components/alert"

const TransitionDataMares = props => {
  const [verresPro, setVerresPro] = useState(0);
  const [nascutsVius, setNascutsVius] = useState(0);
  const [trugesDestete, setTrugesDestete] = useState(0);
  const [tpcBaixesDest, setTpcBaixesDest] = useState(0);
  const [verresNoves, setVerresNoves] = useState(0);
  const [aborts, setAborts] = useState(0);
  const [repeticions, setRepeticions] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [startDateString, setStartDateString] = useState("");
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");
  const timer = useRef(null);
 
  const pad2 = n => {
    return (n < 10 ? '0' : '') + n;
  }
  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  const handleStartDate = data => {
      var month = pad2(data.getMonth()+1);//months (0-11)
      var day = pad2(data.getDate());//day (1-31)
      var year= data.getFullYear();
      var formattedDate =  day+"-"+month+"-"+year;
      setStartDate(data);
      setStartDateString(formattedDate);
    }

    const addVerresPro = (e, enough) => {
      e.preventDefault()
      !enough && parseInt(verresPro) < 1000  && setVerresPro(parseInt(verresPro) + 1)
      clearInterval(timer.current);
    }
    const addVerresProLong = (e) => {
      if (parseInt(verresPro) < 1000) setVerresPro(parseInt(verresPro) + 100)
      else return;
      timer.current = setInterval( () => {
        setVerresPro(verresPro => verresPro + 100)
      }, 500)
    }
    const addNascutsVius = (e, enough) => {
      e.preventDefault()
      !enough && parseInt(nascutsVius) < 10000 && setNascutsVius(parseInt(nascutsVius) + 1)
      clearInterval(timer.current);
    }
    const addNascutsViusLong = (e) => {
      if (parseInt(nascutsVius) < 1000)  setNascutsVius(parseInt(nascutsVius) + 1000)
      else return;
      timer.current = setInterval( () => {
        setNascutsVius(nascutsVius => nascutsVius + 1000)
      }, 500)
    }
    const addTrugesDestete = (e, enough) => {
      e.preventDefault()
      !enough  && parseInt(trugesDestete) < 200 && setTrugesDestete(parseInt(trugesDestete) + 1)
      clearInterval(timer.current); 
    }
    const addTrugesDesteteLong = (e) => {
      if (parseInt(trugesDestete) < 200)  setTrugesDestete(parseInt(trugesDestete) + 10)
      else return;
      timer.current = setInterval( () => {
        setTrugesDestete(trugesDestete => trugesDestete + 10)
      }, 500)
    }
    const addVerresNoves = (e, enough) => {
      e.preventDefault()
      !enough  && parseInt(verresNoves) < 1000 && setVerresNoves(parseInt(verresNoves) + 1)
      clearInterval(timer.current); 
    }
    const addVerresNovesLong = (e) => {
      if (parseInt(verresNoves) < 1000) setVerresNoves(parseInt(verresNoves) + 100)
      else return;
      timer.current = setInterval( () => {
        setVerresNoves(verresNoves => verresNoves + 100)
      }, 500)
    }
    const addAborts = (e, enough) => {
      e.preventDefault()
      !enough  && parseInt(aborts) < 1000 && setAborts(parseInt(aborts) + 1)
      clearInterval(timer.current); 
    }
    const addAbortsLong = (e) => {
      if (parseInt(aborts) < 1000) setAborts(parseInt(aborts) + 100)
      else return;
      timer.current = setInterval( () => {
        setAborts(aborts => aborts + 100)
      }, 500)
    }
    const addRepeticions = (e, enough) => {
      e.preventDefault()
      !enough  && parseInt(repeticions) < 1000 && setRepeticions(parseInt(repeticions) + 1)
      clearInterval(timer.current); 
    }
    const addRepeticionsLong = (e) => {
      if (parseInt(repeticions) < 1000)  setRepeticions(parseInt(repeticions) + 100)
      else return;
      timer.current = setInterval( () => {
        setRepeticions(repeticions => repeticions + 100)
      }, 500)
    }
    const remVerresPro = (e, enough) => {
      e.preventDefault()
      if (parseInt(verresPro) > 1) {
        !enough && setVerresPro(parseInt(verresPro) - 1)
        clearInterval(timer.current);
      }else{
        enough && setVerresPro(parseInt(0))
        clearInterval(timer.current);
      }
    }
    const remVerresProLong = (e) => {
      timer.current = setInterval( () => {
        if (parseInt(verresPro) > 100) {
          setVerresPro(verresPro => verresPro - 100)
        }else{
          setVerresPro(parseInt(0))
        }
      }, 500)
    }
    const remNascutsVius = (e, enough) => {
      e.preventDefault()
      if (parseInt(nascutsVius) > 1) {
        !enough && setNascutsVius(parseInt(nascutsVius) - 1)
        clearInterval(timer.current);
      }else{
        enough && setNascutsVius(parseInt(0))
        clearInterval(timer.current);
      }
    }
    const remNascutsViusLong = (e) => {
      timer.current = setInterval( () => {
        if (parseInt(nascutsVius) > 1000) {
          setNascutsVius(nascutsVius => nascutsVius - 1000)
        }else{
          setNascutsVius(parseInt(0))
        }
      }, 500)
    }
    const remTrugesDestete = (e, enough) => {
      e.preventDefault()
      if (parseInt(trugesDestete) > 1) {
        !enough && setTrugesDestete(parseInt(trugesDestete) - 1)
        clearInterval(timer.current);
      }else{
        enough && setTrugesDestete(parseInt(0))
        clearInterval(timer.current);
      }
    }
    const remTrugesDesteteLong = (e) => {
      timer.current = setInterval( () => {
        if (parseInt(trugesDestete) > 10) {
          setTrugesDestete(trugesDestete => trugesDestete - 10)
        }else{
          setTrugesDestete(parseInt(0))
        }
      }, 500)
    }

    const remVerresNoves = (e, enough) => {
      e.preventDefault()
      if (parseInt(trugesDestete) > 1) {
        !enough && setVerresNoves(parseInt(verresNoves) - 1)
        clearInterval(timer.current);
      }else{
        enough && setVerresNoves(parseInt(0))
        clearInterval(timer.current);
      }
    }
    const remVerresNovesLong = (e) => {
      timer.current = setInterval( () => {
        if (parseInt(verresNoves) > 100) {
          setVerresNoves(verresNoves => verresNoves - 100)
        }else{
          setVerresNoves(parseInt(0))
        }
      }, 500)
    }
    const remAborts = (e, enough) => {
      e.preventDefault()
      if (parseInt(aborts) > 1) {
        !enough && setAborts(parseInt(aborts) - 1)
        clearInterval(timer.current);
      }else{
        enough && setAborts(parseInt(0))
        clearInterval(timer.current);
      }
    }
    const remAbortsLong = (e) => {
      timer.current = setInterval( () => {
        if (parseInt(aborts) > 100) {
          setAborts(aborts => aborts - 100)
        }else{
          setAborts(parseInt(0))
        }
      }, 500)
    }
    const remRepeticions = (e, enough) => {
      e.preventDefault()
      if (parseInt(repeticions) > 1) {
        !enough && setRepeticions(parseInt(repeticions) - 1)
        clearInterval(timer.current);
      }else{
        enough && setRepeticions(parseInt(0))
        clearInterval(timer.current);
      }
    }
    const remRepeticionsLong = (e) => {
      timer.current = setInterval( () => {
        if (parseInt(repeticions) > 100) {
          setRepeticions(repeticions => repeticions - 100)
        }else{
          setRepeticions(parseInt(0))
        }
      }, 500)
    }

    const longPressStart = (e) => {
    }
   
    const onExit = () =>{
        var res=[];
        res.push({
          "key":"Data",
          "value": startDateString
        })
        res.push({
          "key":"Verres Prod.",
          "value": verresPro
        })
        res.push({
          "key":"Nascuts Vius",
          "value": nascutsVius
        })
        res.push({
          "key":"Truges a destete",
          "value": trugesDestete
        })
        res.push({
          "key":"% baixes destete",
          "value": tpcBaixesDest
        })
        res.push({
          "key":"Verres noves",
          "value": verresNoves
        })
        res.push({
          "key":"Aborts",
          "value": aborts
        })
        res.push({
          "key":"Repeticions",
          "value": repeticions
        })
        props.callbackValues.data=startDate.toISOString().substring(0,10);
        props.callbackValues.verresPro=verresPro;
        props.callbackValues.nascutsVius=nascutsVius;
        props.callbackValues.trugesDestete=trugesDestete;
        props.callbackValues.tpcBaixesDest=tpcBaixesDest;
        props.callbackValues.verresNoves=verresNoves;
        props.callbackValues.aborts=aborts;
        props.callbackValues.repeticions=repeticions;
        
        props.callbackStrings(res)

    }
    const handleTcpBaixesDest = event => {
      setTpcBaixesDest(event.target.value)
    }
    const handleVerresPro = event => {
      setVerresPro(event.target.value) 
    }
    const handleNascutsVius = event => {
      setNascutsVius(event.target.value) 
    }
    const handleTrugesDestete = event => {
      setTrugesDestete(event.target.value) 
    }
    const handleVerresNoves = event => {
      setVerresNoves(event.target.value) 
    }
    const handleAborts = event => {
      setAborts(event.target.value) 
    }
    const handleRepeticions = event => {
      setRepeticions(event.target.value) 
    }
    const handleClickUp = (e) => {
      e.preventDefault();
      if(!verresPro){
        setMessageAlertStep("El camp Verres productives és obligatori")
        setShowAlertStep(true)
        return
      }
      if(!nascutsVius){
        setMessageAlertStep("El camp Nascuts vius és obligatori")
        setShowAlertStep(true)
        return
      }
      if(!trugesDestete){
        setMessageAlertStep("El camp Truges a Destete és obligatori")
        setShowAlertStep(true)
        return
      }
      if(tpcBaixesDest && !isNumber(tpcBaixesDest) ){
        setMessageAlertStep("El camp % Baixes destete té format incorrecte, ex: 23.2")
        setShowAlertStep(true)
        return
      }
      
      if(verresPro && verresPro>1000){
        setMessageAlertStep("Verres Productives no pot ser superior a 1000")
        setShowAlertStep(true)
        return
      }
      if(nascutsVius && nascutsVius>10000){
        setMessageAlertStep("Nascuts Vius no pot ser superior a 10000")
        setShowAlertStep(true)
        return
      }
      if(trugesDestete && trugesDestete>200){
        setMessageAlertStep("Truges Destete no pot ser superior a 200")
        setShowAlertStep(true)
        return
      }
      if(verresNoves && verresNoves>1000){
        setMessageAlertStep("Verres Noves no pot ser superior a 200")
        setShowAlertStep(true)
        return
      }
      if(aborts && aborts>1000){
        setMessageAlertStep("Aborts no pot ser superior a 1000")
        setShowAlertStep(true)
        return
      }
      if(repeticions && repeticions>1000){
        setMessageAlertStep("Repeticions no pot ser superior a 1000")
        setShowAlertStep(true)
        return
      }
      setShowAlertStep(false)
      props.handleStepActiveUp(props.stepActive)
    }

    const handleClickDown = (e) => {
      e.preventDefault();
      props.handleStepActiveDown(props.stepActive)
    }

    useEffect( () => {
      var data =new Date();
      var month = pad2(data.getMonth()+1);//months (0-11)
      var day = pad2(data.getDate());//day (1-31)
      var year= data.getFullYear();
      var formattedDate =  day+"-"+month+"-"+year;
      if (!startDateString || startDateString==="") setStartDateString(formattedDate)
    }, []);


    return (
      <span>
      <CSSTransition
        in={props.stepActive === props.index}
        timeout={300}
        onExit={onExit}
        classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
        unmountOnExit
      >
      <div className="steps-panel">
        <div className="steps-panel-scroller">
          <div className="field-wrapper-row">
            <div className="field-wrapper-col-12">
              <div className={'field-wrapper field-wrapper-datepicker dirty'}>
                <DatePicker dateFormat="dd/MM/yyyy" maxDate={new Date()} selected={startDate} name="data" onChange={handleStartDate} />
                <label htmlFor="date">Data</label>
              </div>
            </div>
          </div>
          <div className="field-wrapper-row">
            <div className="field-wrapper-col-6">
              <div className="field-wrapper field-wrapper-number">
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={remVerresProLong}
                  onEnd={remVerresPro}
                  className="field-wrapper-number-decrease">
                    <span>-</span>
                </ClickNHold>
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={addVerresProLong}
                  onEnd={addVerresPro}
                  className="field-wrapper-number-increase">
                    <span>+</span>
                </ClickNHold>
                <input className="field-input dirty" type="number" name="verresPro" min="1" max="1000" value={verresPro} onChange={handleVerresPro} required/>
                <label htmlFor="numanimals">Verres Productives</label>
              </div>
            </div>
            <div className="field-wrapper-col-6">
              <div className="field-wrapper field-wrapper-number">
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={remNascutsViusLong}
                  className="field-wrapper-number-decrease"
                  onEnd={remNascutsVius}>
                    <span>-</span>
                </ClickNHold>
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={addNascutsViusLong}
                  onEnd={addNascutsVius}
                  className="field-wrapper-number-increase">
                    <span>+</span>
                </ClickNHold>
                <input className="field-input dirty" type="number" name="nascutsVius" min="1" max="10000" onChange={handleNascutsVius} value={nascutsVius} required/>
                <label htmlFor="pesviu">Nascuts Vius</label>
              </div>
            </div>
          </div>
          <div className="field-wrapper-row">
            <div className="field-wrapper-col-6">
              <div className="field-wrapper field-wrapper-number">
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={remTrugesDesteteLong}
                  className="field-wrapper-number-decrease"
                  onEnd={remTrugesDestete}>
                    <span>-</span>
                </ClickNHold>
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={addTrugesDesteteLong}
                  onEnd={addTrugesDestete}
                  className="field-wrapper-number-increase">
                    <span>+</span>
                </ClickNHold>
                <input className="field-input dirty" type="number" name="trugesDestete" min="1" nax="200" onChange={handleTrugesDestete} value={trugesDestete} required/>
                <label htmlFor="pesviu">Truges Destete</label>
              </div>
            </div>
            <div className="field-wrapper-col-6">
              <div className="field-wrapper">
                <input className="field-input dirty" type="text" name="tpcBaixesDest" value={tpcBaixesDest} onChange={handleTcpBaixesDest}/>
                <label htmlFor="pesmig">% Baixes destete</label>
              </div>
            </div>
          </div>
          <div className="field-wrapper-row">
            <div className="field-wrapper-col-6">
              <div className="field-wrapper field-wrapper-number">
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={remVerresNovesLong}
                  className="field-wrapper-number-decrease"
                  onEnd={remVerresNoves}>
                    <span>-</span>
                </ClickNHold>
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={addVerresNovesLong}
                  onEnd={addVerresNoves}
                  className="field-wrapper-number-increase">
                    <span>+</span>
                </ClickNHold>
                <input className="field-input dirty" type="number" name="verresNoves" min="1" nax="1000" onChange={handleVerresNoves} value={verresNoves} required/>
                <label htmlFor="verresnoves">Verres Noves</label>
              </div>
            </div>
            <div className="field-wrapper-col-6">
              <div className="field-wrapper field-wrapper-number">
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={remRepeticionsLong}
                  className="field-wrapper-number-decrease"
                  onEnd={remRepeticions}>
                    <span>-</span>
                </ClickNHold>
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={addRepeticionsLong}
                  onEnd={addRepeticions}
                  className="field-wrapper-number-increase">
                    <span>+</span>
                </ClickNHold>
                <input className="field-input dirty" type="number" name="repeticions" min="1" max="1000" onChange={handleRepeticions} value={repeticions} required/>
                <label htmlFor="aborts">Repeticions</label>
              </div>
            </div>
          </div>
          <div className="field-wrapper-row">
            <div className="field-wrapper-col-6">
              <div className="field-wrapper field-wrapper-number">
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={remAbortsLong}
                  className="field-wrapper-number-decrease"
                  onEnd={remAborts}>
                    <span>-</span>
                </ClickNHold>
                <ClickNHold 
                  time={1}
                  onStart={longPressStart}
                  onClickNHold={addAbortsLong}
                  onEnd={addAborts}
                  className="field-wrapper-number-increase">
                    <span>+</span>
                </ClickNHold>
                <input className="field-input dirty" type="number" name="aborts" min="1" max="1000" onChange={handleAborts} value={aborts} required/>
                <label htmlFor="aborts">Aborts</label>
              </div>
            </div>
            <div className="field-wrapper-col-6">
              <div className="field-wrapper field-wrapper-number">&nbsp;</div>
            </div>
          </div>
        </div>
        <div className="steps-nav">
          <button onClick={handleClickDown} className={props.stepActive <= 1 ? classNames('disabled', 'steps-nav-btn', 'steps-nav-btn-prev') : classNames('steps-nav-btn', 'steps-nav-btn-prev')}>
            <span>Prev</span>
          </button>
          <button onClick={handleClickUp} className={props.stepActive >= 5 ? classNames('disabled', 'steps-nav-btn steps-nav-btn-next') : 'steps-nav-btn steps-nav-btn-next'}>
            <span>Next</span>
          </button>
        </div>
      </div>
    </CSSTransition>
    <CSSTransition
        in={showAlertStep}
        timeout={300}
        classNames={'alertright'}
        unmountOnExit>
      <Alert type="error">
        <div className="alert-message">
        {messageAlertStep}
        </div>
        <span className="alert-close" onClick={closeAlertStep}>
          <i className="icon-close"></i>
        </span>
      </Alert>
  </CSSTransition>
 </span>
  )
}
export default TransitionDataMares


function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}