import React, { useState, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group';
import classNames from "classnames"

const TransitionResult = props => {
  return (
        <CSSTransition
          in={props.stepActive === props.index}
          timeout={300}
          classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
          unmountOnExit
        >
          <div className="steps-panel last">
              <div className="steps-panel-scroller">
                <div className="form-summary">
                  
                   { props.arrayData.map((result) => (
                      <div className="form-summary-item">
                        <div className="label">{result.key}</div>
                        <div className="value">{result.value}</div>
                      </div>
                   ))}
              </div>
              <div className="form-submit-wrapper">
                <button className="btn-secondary btn--xs steps-nav-init" onClick={props.resetStepActive}>Modificar</button>
                <button className="btn-secondary btn--xs steps-nav-init" onClick={props.handleConfirm}>Confirmar</button>
              </div>
            </div>
            </div>
        </CSSTransition>

    )
}

export default TransitionResult